import React from 'react';
import { Flex, Text, Heading } from '@chakra-ui/core';
import Helmet from 'react-helmet';

import { colors } from '@theme';
import Layout from '../components/layout';

import Employee from '../components/aboutUs/employee';
import Investor from '../components/aboutUs/investor';
import { Button } from '@zeta';

const Index = () => {
  const investors = [
    {
      name: 'Dan Kimerling',
      line1: 'Deciens Capital',
      line2: 'Silicon Valley Bank',
    },
    {
      name: 'Charles Hudson',
      line1: 'Precursor Ventures',
      line2: 'Stanford',
    },
    {
      name: 'Rohini Pandhi',
      line1: 'Square',
      line2: 'Transparent Collective',
    },
    {
      name: 'Lauren Berson',
      line1: 'Weight Watchers',
      line2: 'Google',
    },
    {
      name: 'Maia Bittner',
      line1: 'Chime',
      line2: 'Rocksbox',
    },
    {
      name: 'Mike Vaughan',
      line1: 'Venmo',
      line2: 'Paypal',
    },
    {
      name: 'Sarah Imbach',
      line1: 'Paypal, 23&Me',
      line2: 'LinkedIn',
    },
    {
      name: 'Elain Szu',
      line1: 'Sentry',
      line2: 'Accel',
    },
    {
      name: 'Farnoosh Torabi',
      line1: 'So Money Podcast',
      line2: 'CNBC',
    },
  ];

  return (
    <>
      <Helmet>
        <title>About Zeta: A financial institution for families | Zeta</title>
        <link rel="canonical" href="https://www.askzeta.com/aboutus" />
        <meta
          name="description"
          content="Learn about Zeta, a financial institution focused on helping families thrive. Discover our mission and commitment to empowering your financial journey."
        />
        <meta
          property="og:description"
          content="Learn about Zeta, a financial institution focused on helping families thrive. Discover our mission and commitment to empowering your financial journey."
        />
        <link rel="shortcut icon" href="/favicon.ico" />
        <meta
          name="keywords"
          content="personal finance, banking, joint banking, joint account, couples finance, family finance, love and money, combining finances, sharing finances"
        />
        <meta property="og:locale" content="en_US" />
        <meta property="og:title" content="Zeta | Master Money as a Team" />
        <meta property="og:site_name" content="Zeta | Master Money as a Team" />
        <meta property="og:url" content="https://www.askzeta.com/aboutus" />
        <meta property="og:type" content="page" />
        <meta
          property="og:image"
          content="https://d142whjg6dsdg2.cloudfront.net/logos/zeta-circle.png"
        />
        <meta
          property="og:image:secure_url"
          content="https://d142whjg6dsdg2.cloudfront.net/logos/zeta-circle.png"
        />
        <meta property="fb:app_id" content="2316257168617802" />
      </Helmet>
      <Layout noGetStarted mode={'home'} newNav>
        <Flex direction="column" mt={['6rem', '6rem']}>
          <Flex justifyContent="center" direction="column" pt={[0, 16]}>
            <Heading textAlign="center" color={colors.primary} fontSize="5xl" as="h1">
              About Us
            </Heading>
          </Flex>

          <Flex className="aboutus">
            <Flex py={8} direction="column">
              <Flex direction="column" mb={[4, 6]} px={[4, 0]}>
                <Text pt={[6, 0]} fontSize={['md', 'lg']}>
                  Somehow, our schooling doesn’t really prepare us to be masters of
                  finance. Many of us hack together our financial education with advice
                  from our parents (warranted or not), our friends or Google searches.
                  Worse, nearly 65% of divorces are attributed (at least in part) to
                  money.
                  <br />
                  <br />
                  At Zeta, we believe that mastering your money together is a core skill
                  for every relationship. Using a combination of technology, support and
                  advice, we want to help 130 million American families get ahead of their
                  finances. From planning for your first paycheck to paying for college
                  debt, saving for your first house to prepping for child #1, Zeta’s
                  mission is to help couples and families thrive.
                  <br />
                  <br />
                  You can read our founding story{' '}
                  <a
                    style={{ color: colors.shared }}
                    href="https://www.askzeta.com/magazine/articles/zeta-personal-finance-for-couples/"
                  >
                    here.
                  </a>
                  <br />
                  <br />
                  PS: Your favorite banking app isn’t just built by incredible humans. In
                  fact, we have an entire pack of Zeta Pets who make our work possible
                  everyday. You can meet them{' '}
                  <a style={{ color: colors.shared }} href="/petsofzeta">
                    here
                  </a>
                  .
                </Text>
              </Flex>
            </Flex>
          </Flex>

          <Flex justifyContent="center" direction="column" pt={[6, 16]}>
            <Heading
              textAlign="center"
              color={colors.primary}
              pt={[4, 0]}
              fontSize="5xl"
              as="h2"
            >
              Backed by World-Class Investors
            </Heading>
            <Text fontSize="xl" textAlign="center" my={6} px={[4, 0]}>
              Our investors include a group of diverse, experienced and hands-on
              <br />
              operators and venture investors from financial and consumer industries.
            </Text>
          </Flex>
          <Flex className="investors" flexWrap="wrap" my={8}>
            {investors.map(investor => {
              return <Investor investor={investor} />;
            })}
          </Flex>

          <Flex justifyContent="center" my={12} pb={12}>
            <a href="https://app.instapage.com/route/6593471/?url=WWW.ZETAHELP.COM/careers">
              <Button variant="secondary" text="Join Our Team" large />
            </a>
          </Flex>
        </Flex>
      </Layout>
    </>
  );
};

export default Index;
