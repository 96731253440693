import React from 'react';
import { Flex, Text, Heading, Image } from '@chakra-ui/core';
import theme, { colors } from '@theme';

export default ({ investor }) => {
  const { name, line1, line2 } = investor;
  return (
    <Flex direction="column" width={['100%', '33%']} px={4} my={6}>
      <Flex direction="column" justify="flex-end">
        <Text
          textAlign="center"
          fontWeight={theme.fontWeights.bold}
          fontSize="xl"
          color={colors.greenPea}
        >
          {name.toUpperCase()}
        </Text>
        <Text textAlign="center" color={colors.boulder} fontSize="xl">
          {line1}
          <br />
          {line2}
        </Text>
      </Flex>
    </Flex>
  );
};
